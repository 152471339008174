import { createContext, PropsWithChildren, useContext } from "react";
import { MultiResponseNodeType } from "./NodeStateContext";

// Context interface
interface NodeInfoContext {
    nodeType?: MultiResponseNodeType;
    id?: string;
    // Add any other relevant properties
}

// Create the context
const NodeInfoContext = createContext<NodeInfoContext | null>(null);

// Create a provider component
export function NodeInfoProvider({
    children,
    ...value
}: PropsWithChildren<NodeInfoContext>) {
    return (
        <NodeInfoContext.Provider value={value}>
            {children}
        </NodeInfoContext.Provider>
    );
}

// Custom hook to use the context
export function useNodeInfoContext() {
    const context = useContext(NodeInfoContext);
    if (!context) {
        throw new Error(
            "useNodeInfoContext must be used within a NodeInfoProvider"
        );
    }
    return context;
}
