import { Box } from "@mui/material";
import React from "react";
import useNodeContext from "../../hooks/useNodeContext";
import { isDefined } from "@convin/utils/helper/common.helper";
import NodeStatePorvider from "../../context/NodeStatePorvider";
import { NodeStateProviderProps } from "../../context/NodeStateContext";
import ConfigureSingleResponseBlockType from "../ConfigureSingleBlockType";
import ChooseMultiResponseNode from "./ChooseMultiResponseNode";

const CreateNode: React.FC = () => {
    const { state } = useNodeContext();
    return (
        <>
            {isDefined(state.selectedBlockType) ? (
                <Box className="w-[416px]">
                    <ConfigureSingleResponseBlockType />
                </Box>
            ) : (
                <Box className="w-[416px]">
                    <ChooseMultiResponseNode />
                </Box>
            )}
        </>
    );
};

export default function CreateMutiResponseBlockType(
    props: NodeStateProviderProps
) {
    return (
        <NodeStatePorvider {...props}>
            <CreateNode />
        </NodeStatePorvider>
    );
}
