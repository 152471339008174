import React from "react";

import CreatorNode from "../CreatorNode";
import CreateMutiResponseBlockType from "./CreateMutiResponseBlockType";

const CreateMultipleResponseNode: React.FC = (): JSX.Element => {
    return (
        <CreatorNode>
            <CreateMutiResponseBlockType
                onNodeSaveCallBack={() => {}}
                showDefaultRuleButton
                hideResponseBlock
            />
        </CreatorNode>
    );
};

export default CreateMultipleResponseNode;
