import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    getInternalCoachingClipsPayload,
    TemplateWithCategories,
} from "@convin/type/Audit";
import {
    GenerateInternalCoachingThread,
    GenerateSessionForm,
} from "@convin/type/InternalCoaching";
import { PaginationType } from "@convin/type/Common";
import { Clip } from "@convin/type/coaching";

// Define the base query configuration

// API Service
export const internalCoachingApiSlice = createApi({
    reducerPath: "internalCoachingApi",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        // Fetch the thread

        getThreadStatus: builder.mutation<string, { thread_id: string }>({
            query: (payload) => ({
                url: "coachings/internal_coaching/",
                method: "POST",
                body: payload,
            }),
        }),
        // Fetch questions based on templates
        getTemplateInfoForCoaching: builder.query<
            TemplateWithCategories,
            {
                template_id: number;
                questions: boolean;
                is_clip_count_enable: boolean;
            }
        >({
            query: (params) => {
                return {
                    url: "/audit/template/retrieve-info/",
                    params,
                };
            },
        }),

        // Fetch internal coaching thread
        getInternalCoachingThread: builder.mutation<
            GenerateInternalCoachingThread,
            Omit<GenerateSessionForm, "start_time" | "end_time"> & {
                start_time: string;
                end_time: string;
            }
        >({
            query: (payload) => ({
                url: "coachings/internal_coaching/",
                method: "POST",
                body: payload,
            }),
        }),

        // Fetch internal coaching clips
        getInternalCoachingClips: builder.query<
            PaginationType<Clip>,
            getInternalCoachingClipsPayload
        >({
            query: ({ id: question_id, status, next, ...payload }) => ({
                url: next || "coachings/internal_clip/",
                method: "POST",
                body: status
                    ? { question_id, status, ...payload }
                    : { question_id, ...payload },
            }),
        }),

        // Fetch next internal coaching clips
        getNextInternalCoachingClips: builder.query({
            query: ({ next, id: question_id, status, ...payload }) => ({
                url: next,
                method: "POST",
                body:
                    status && status.toLowerCase() !== "all"
                        ? { question_id, status, ...payload }
                        : { question_id, ...payload },
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.results.map(({ id }: { id: string }) => ({
                              type: "Clips",
                              id,
                          })),
                          { type: "Clips", id: "LIST" },
                      ]
                    : [{ type: "Clips", id: "LIST" }],
        }),

        // Delete internal coaching clip
        deleteInternalCoachingClip: builder.mutation<void, string>({
            query: (id) => ({
                url: `coachings/internal_clip/${id}/`,
                method: "DELETE",
            }),
        }),

        // Verify internal coaching clip
        verifyInternalCoachingClip: builder.mutation<
            Clip,
            Pick<Clip, "id" | "end_time" | "start_time" | "status">
        >({
            query: ({ id, ...payload }) => ({
                url: `coachings/internal_clip/${id}/`,
                method: "PATCH",
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetThreadStatusMutation,
    useGetInternalCoachingThreadMutation,
    useGetInternalCoachingClipsQuery,
    useLazyGetInternalCoachingClipsQuery,
    useGetNextInternalCoachingClipsQuery,
    useLazyGetNextInternalCoachingClipsQuery,
    useDeleteInternalCoachingClipMutation,
    useVerifyInternalCoachingClipMutation,
    useGetTemplateInfoForCoachingQuery,
} = internalCoachingApiSlice;
