import { NodeProps } from "reactflow";
import { Box } from "@mui/material";

import HandlerWrapper from "../Handlers/HandlerWrapper";
import CreateSingleResponseBlockType from "./CreateSingleResponseBlockType";
import EditLegendSvg from "../../svg/EditLegendSvg";
import DeleteLegendSvg from "../../svg/DeleteLegendSvg";
import NotOperatorLegendSvg from "../../svg/NotOperatorLegendSvg";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import BlockNodeUI from "./BlockNodeUI";
import { GroupNodeType } from "../../../types/bert";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import DeleteButton from "@convin/components/custom_components/Button/DeleteButton";
import { NodeInfoProvider } from "../../context/NodeInfoContext";

const GroupBlockNode: React.FC<NodeProps<GroupNodeType>> = (props) => {
    const {
        dispatch,
        aiConfigState: { activeGroupBlock },
    } = useParameterAiConfigContext();
    const { deleteNode, setNodes } = useReactFlowStateContextProvider();
    const {
        id,
        data: {
            is_not,
            metadata: { name },
        },
    } = props;
    return (
        <NodeInfoProvider>
            <HandlerWrapper
                AddComponent={CreateSingleResponseBlockType}
                {...props}
            >
                <BlockNodeUI
                    {...{
                        name,
                        heading: "Group Block",
                        isGroupNode: true,
                        is_not,
                        is_active: activeGroupBlock?.blockId === id,
                    }}
                >
                    <Box className="flex items-center" gap={0.5}>
                        <button
                            onClick={() => {
                                dispatch({
                                    type: "SET_ACTIVE_GROUP_BLOCK_ID",
                                    payload: {
                                        blockId: id,
                                    },
                                });
                            }}
                        >
                            <EditLegendSvg />
                        </button>
                        <DeleteButton
                            onDelete={() => {
                                return new Promise(() => {
                                    deleteNode(id);
                                });
                            }}
                            title="Delete Node"
                            message="Are you sure you want to delete this Node?"
                            DeleteOptionComponent={
                                <button>
                                    <DeleteLegendSvg />
                                </button>
                            }
                        />
                        <button
                            onClick={() => {
                                setNodes((nodes) =>
                                    nodes.map((e) =>
                                        e.id === id
                                            ? {
                                                  ...e,
                                                  data: {
                                                      ...e.data,
                                                      is_not: !is_not,
                                                  },
                                              }
                                            : e
                                    )
                                );
                            }}
                        >
                            <NotOperatorLegendSvg />
                        </button>
                    </Box>
                </BlockNodeUI>
            </HandlerWrapper>
        </NodeInfoProvider>
    );
};

export default GroupBlockNode;
