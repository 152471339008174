import ReactPlayer from "react-player";
import { showToast } from "@convin/utils/toast";
import {
    Box,
    CircularProgress,
    Paper,
    Slider,
    Typography,
    styled,
    useTheme,
} from "@mui/material";

import { formatTimeHHMMSS } from "@convin/utils/helper/date.helper";
import { useEffect, useState } from "react";
import useMediaPlayerContext from "../../hooks/useMediaPlayerContext";
import {
    checkFileType,
    extractFileType,
    isDarkMode,
    isDefined,
} from "@convin/utils/helper/common.helper";
import poster from "@convin/assets/images/jpeg/media_player_poster.jpg";
import {
    PauseSvg,
    PlaySvg,
    ExpandPlayerSvg,
    ForwardSvg,
    RewindSvg,
    VolumeSvg,
    VolumeMuteSvg,
    CollapsePlayerSvg,
} from "./MediaPlayerIcons";
import MediaPlayerSettings from "./MediaPlayerSettings";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import ClosedCaptionDisabledIcon from "@mui/icons-material/ClosedCaptionDisabled";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";

const StyledPlayerOverlay = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isExpanded" && prop !== "loaded",
})<{ isExpanded: boolean; loaded: number }>(
    ({ isExpanded, loaded, theme }) => ({
        color: isExpanded ? "white" : theme.palette.textColors["666"],
        position: "absolute",
        boxSizing: "border-box",
        top: 0,
        width: "100%",
        borderRadius: isExpanded ? "0px 0px 12px 12px" : "30px",
        opacity: isExpanded ? 0 : 1,
        transition: "opacity 0.2s ease-in-out",
        // maxHeight: "112px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        "& .MuiSlider-root": {
            height: "6px",
            background: isExpanded
                ? "rgba(255, 255, 255, 0.40)"
                : "rgba(153, 153, 153, 0.20)",
        },
        "& .MuiSlider-rail": {
            background: isExpanded
                ? "rgba(255, 255, 255, 0.40)"
                : "rgba(153, 153, 153, 0.20)",
            position: "relative",
            "::after": {
                content: "''",
                width: `${loaded * 100}%`, // loaded %
                height: "inherit",
                background: isExpanded
                    ? "rgba(255, 255, 255, 1)"
                    : "rgba(153, 153, 153, 0.40)",
                display: "block",
                borderRadius: "30px",
            },
        },
        "& .MuiSlider-track": {
            background: isExpanded ? "white" : theme.palette.primary.main,
        },
        "& .MuiSlider-thumb": {
            border: "none",
        },
    })
);

export default function MediaPlayer({
    mediaUrl,
    subtitles,
    height,
    canDownload = true,
    isSnippet = false,
    startTime,
    endTime,
}: {
    mediaUrl?: string;
    subtitles?: string;
    height?: number;
    canDownload?: boolean;
    startTime?: number;
    endTime?: number;
    isSnippet?: boolean;
}) {
    const { data: domainConfig } = useGetDomainConfigQuery();
    const {
        playerRef,
        playerState,
        handlePreview,
        handlePlayPause,
        handlePlay,
        handlePause,
        handleEnded,
        handleProgress,
        handleDuration,
        handlePlayerControlSliderSeek,
        seekBy10seconds,
        toggleCaptions,
        togglePip,
        handleVolumeChange,
        handleEnablePip,
        toggleMute,
        hideController,
    } = useMediaPlayerContext();
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    function calculateDuration(value: number) {
        return formatTimeHHMMSS(value);
    }

    function calculateVolume(value: number) {
        return `${Math.floor(value * 100)}%`;
    }

    //For enabling and disabling subtitles
    useEffect(() => {
        if (!isDefined(playerRef.current)) return;
        if (
            !isDefined(
                playerRef?.current?.getInternalPlayer()?.textTracks?.[0]?.mode
            )
        )
            return;
        if (playerState.captionsEnabled) {
            playerRef.current.getInternalPlayer().textTracks[0].mode =
                "showing";
        } else {
            playerRef.current.getInternalPlayer().textTracks[0].mode = "hidden";
        }
    }, [playerState.captionsEnabled]);

    useEffect(() => {
        return () => {
            playerRef.current = null;
        };
    }, []);

    useEffect(() => {
        if (playerState.isFullscreen) {
            setIsExpanded(true);
        }
    }, [playerState.isFullscreen]);

    const isVideo = checkFileType(extractFileType(mediaUrl ?? "")) === "video";
    const theme = useTheme();
    return (
        <Box
            className="w-[100%] relative react-player-container overflow-hidden"
            sx={{
                ...(!isVideo && {
                    "& .react-player--picture__in__option": {
                        display: "none",
                    },
                }),
                ...(!isExpanded
                    ? {
                          video: {
                              visibility: "hidden",
                          },
                          height: "54px",
                          borderRadius: "30px",
                      }
                    : {
                          height: `${height || 260}px`,
                          bgcolor: (theme) =>
                              isDarkMode(theme) ? "background.default" : "#121",
                          borderRadius: "12px",
                          "&:hover": {
                              ".video-player__controls": {
                                  opacity: 1,
                              },
                          },
                      }),

                transition: "all 0.3s ease",
            }}
        >
            {isLoading && isExpanded && (
                <Box
                    component="button"
                    sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        color: "white",
                    }}
                    className="flex items-center justify-center absolute"
                >
                    <CircularProgress color="inherit" />
                </Box>
            )}
            {!isLoading && isExpanded && !playerState.playing && (
                <Box
                    component="button"
                    sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        bgcolor: "rgba(255, 255, 255, 0.40)",
                        width: "40px",
                        height: "40px",
                        borderRadius: "100%",
                        color: "white",
                        zIndex: 100,
                    }}
                    className="flex items-center justify-center absolute"
                    onClick={handlePlay}
                >
                    <PlaySvg />
                </Box>
            )}

            <ReactPlayer
                className="react-player"
                height={"100%"}
                width={"100%"}
                url={mediaUrl}
                ref={playerRef}
                config={{
                    file: {
                        forceVideo: true,
                        attributes: {
                            ...(!isDefined(domainConfig?.logo) && { poster }),
                            crossOrigin: "anonymous", // required for subtitles to be shown
                        },
                        ...(isDefined(subtitles) && {
                            tracks: [
                                {
                                    kind: "captions",
                                    src: subtitles!,
                                    default: true,
                                    srcLang: "en",
                                    label: "Subtitles",
                                },
                            ],
                        }),
                    },
                }}
                onError={() => {
                    showToast({
                        type: "error",
                        message:
                            "Player has encountered an error. Please try refreshing the page.",
                    });
                    setIsLoading(false);
                }}
                controls={playerState.controls}
                pip={playerState.pip}
                loop={playerState.loop}
                muted={playerState.muted}
                playing={playerState.playing}
                playbackRate={playerState.playbackRate}
                progressInterval={100}
                volume={playerState.volume}
                onPlay={handlePlay}
                onEnded={handleEnded}
                onPause={handlePause}
                onDuration={handleDuration}
                onProgress={handleProgress}
                onClickPreview={handlePreview}
                onEnablePIP={handleEnablePip}
                onDisablePIP={togglePip}
                onBuffer={() => {
                    setIsLoading(true);
                }}
                onBufferEnd={() => {
                    setIsLoading(false);
                }}
                onReady={() => {
                    setIsLoading(false);
                }}
            />
            <StyledPlayerOverlay
                isExpanded={isExpanded}
                loaded={playerState.loaded}
                sx={{
                    ...(!playerState.playing && {
                        opacity: 1,
                    }),
                }}
                className="video-player__controls"
                onClick={() => {
                    if (isLoading) return;
                    handlePlayPause();
                }}
            >
                <Box className="relative">
                    <Box
                        className="absolute bottom-0 left-0 w-full"
                        sx={(theme) => ({
                            background: isExpanded
                                ? !isDarkMode(theme)
                                    ? "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 20.14%, #333 91.18%)"
                                    : "transparent"
                                : isDarkMode(theme)
                                ? "background.paper"
                                : "#F5F5F5",
                            borderRadius: isExpanded
                                ? "0px 0px 12px 12px"
                                : "30px",
                        })}
                        component={isDarkMode(theme) ? Paper : "div"}
                    >
                        <Box
                            className="flex items-center justify-between gap-[8px]"
                            sx={{
                                m: 4,
                                ...(!isExpanded && { mb: 1.5 }),
                            }}
                        >
                            <Box className="flex justify-start items-center gap-2 flex-1">
                                {hideController ? (
                                    <></>
                                ) : (
                                    <Box
                                        component="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            seekBy10seconds("back");
                                        }}
                                    >
                                        <RewindSvg />
                                    </Box>
                                )}

                                <Box
                                    component={"button"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handlePlayPause();
                                    }}
                                >
                                    {playerState.playing ? (
                                        <PauseSvg />
                                    ) : (
                                        <PlaySvg />
                                    )}
                                </Box>

                                {hideController ? null : (
                                    <Box
                                        component="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            seekBy10seconds("forward");
                                        }}
                                    >
                                        <ForwardSvg />
                                    </Box>
                                )}

                                <Typography
                                    variant="small"
                                    sx={{
                                        color: isExpanded
                                            ? "white"
                                            : "textColors.333",
                                        mt: 0.25,
                                    }}
                                >
                                    {`${formatTimeHHMMSS(
                                        isSnippet &&
                                            isDefined(endTime) &&
                                            isDefined(startTime)
                                            ? Math.max(
                                                  playerState.playedSeconds -
                                                      startTime,
                                                  0
                                              )
                                            : playerState.playedSeconds
                                    )}/${formatTimeHHMMSS(
                                        isSnippet &&
                                            isDefined(endTime) &&
                                            isDefined(startTime)
                                            ? endTime - startTime
                                            : playerState.duration
                                    )}`}
                                </Typography>
                                {hideController ? null : (
                                    <Box
                                        className="flex-1"
                                        sx={{
                                            px: 0.5,
                                            pl: 1,
                                            height: "28px",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Slider
                                            size="small"
                                            value={playerState?.playedSeconds}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={playerState?.duration}
                                            step={1}
                                            sx={{
                                                p: 0,
                                                top: 0,
                                            }}
                                            onChange={
                                                handlePlayerControlSliderSeek
                                            }
                                            valueLabelFormat={calculateDuration}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <Box className="flex justify-evenly items-center gap-2 -mt-1.5">
                                <Box
                                    className="w-[100px] h-[32px] flex items-center justify-center"
                                    sx={(theme) => ({
                                        p: 1,
                                        pl: 1.5,
                                        bgcolor: isExpanded
                                            ? "rgba(227, 227, 227, 0.20)"
                                            : isDarkMode(theme)
                                            ? "background.paper"
                                            : "#F5F5F5",
                                        borderRadius: "30px",
                                    })}
                                    gap={1}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Box className="flex-1">
                                        <Slider
                                            size="small"
                                            value={playerState?.volume}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={1}
                                            step={0.01}
                                            sx={{
                                                p: 0,
                                                top: 0,
                                            }}
                                            onChange={handleVolumeChange}
                                            valueLabelFormat={calculateVolume}
                                        />
                                    </Box>
                                    <Box
                                        component={"button"}
                                        onClick={toggleMute}
                                    >
                                        {playerState.muted ? (
                                            <VolumeMuteSvg />
                                        ) : (
                                            <VolumeSvg />
                                        )}
                                    </Box>
                                </Box>
                                {hideController ? (
                                    <></>
                                ) : (
                                    <>
                                        {isExpanded && isDefined(subtitles) && (
                                            <Box
                                                component="button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleCaptions();
                                                }}
                                            >
                                                {playerState.captionsEnabled ? (
                                                    <ClosedCaptionIcon />
                                                ) : (
                                                    <ClosedCaptionDisabledIcon />
                                                )}
                                            </Box>
                                        )}
                                        <MediaPlayerSettings
                                            canDownload={canDownload}
                                        />
                                        {!playerState.isFullscreen && (
                                            <Box
                                                component="button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setIsExpanded(
                                                        (prev) => !prev
                                                    );
                                                }}
                                                className="expand-player-button"
                                            >
                                                {isExpanded ? (
                                                    <CollapsePlayerSvg />
                                                ) : (
                                                    <ExpandPlayerSvg />
                                                )}
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </StyledPlayerOverlay>
        </Box>
    );
}
