import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function GptBlockSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <path
                d="M15.8612 7.49479L14.8178 5.20312L12.5223 4.16146L14.8178 3.11979L15.8612 0.828125L16.9047 3.11979L19.2002 4.16146L16.9047 5.20312L15.8612 7.49479ZM15.8612 19.1615L14.8178 16.8698L12.5223 15.8281L14.8178 14.7865L15.8612 12.4948L16.9047 14.7865L19.2002 15.8281L16.9047 16.8698L15.8612 19.1615ZM7.51385 16.6615L5.427 12.0781L0.835938 9.99479L5.427 7.91146L7.51385 3.32812L9.60069 7.91146L14.1918 9.99479L9.60069 12.0781L7.51385 16.6615ZM7.51385 12.6198L8.34859 10.8281L10.1433 9.99479L8.34859 9.16146L7.51385 7.36979L6.67911 9.16146L4.88442 9.99479L6.67911 10.8281L7.51385 12.6198Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
