import { format } from "date-fns";
import isEqual from "lodash.isequal";
import { datekeys, defaultConfig } from "@convin/config/default.config";

import { isDefined } from "./common.helper";
import { nanoid } from "@reduxjs/toolkit";

export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const day = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const getDateTime = ({
    isoDate,
    type = "dateTime",
    separator = " ",
    format,
}: {
    isoDate: number | Date | string | null | undefined;
    type?: string;
    separator?: string;
    format?:
        | "dd MM, YYYY"
        | "MM dd, YYYY"
        | "MM dd"
        | "dd MM, T"
        | "T,MM dd, YYYY"
        | "dd MM YYYY"
        | "dd MM, YYYY | T"
        | "dd MM, YYYY - T"
        | "dd MM | T"
        | "day, dd"
        | "day, dd MM YYYY"
        | "MM 'YY"
        | "T"
        | "MM dd"
        | "dd MM 'YY"
        | "dd MM"
        | "dd MM, 'YY - T"
        | "T, dd, MM, YYYY"
        | "MM dd, T";
}): string => {
    if (!isDefined(isoDate)) return "";
    let returnTime = "";
    let returnDate = "";
    const fullDateTime = new Date(isoDate);

    const year = fullDateTime.getFullYear();
    const month = fullDateTime.getMonth();
    let date = String(fullDateTime.getDate());
    const hour: number = fullDateTime.getHours();
    let minutes: string | number = fullDateTime.getMinutes();
    const dayName = day[fullDateTime.getDay()];

    if (+date < 10) {
        date = `0${date}`;
    }
    if (+minutes < 10) {
        minutes = `0${minutes}`;
    }

    if (isDefined(hour)) {
        if (hour < 12 || hour === 24) {
            returnTime = `${hour % 12 || 12}:${minutes} AM`;
        } else {
            returnTime = `${hour % 12 || 12}:${minutes} PM`;
        }
    }

    switch (format) {
        case "dd MM, YYYY":
            return `${months[month]} ${date}, ${year}`;
        case "T,MM dd, YYYY":
            return `${returnTime},${months[month]} ${date}, ${year}`;
        case "T, dd, MM, YYYY":
            return `${returnTime}, ${date}th ${months[month]} ${year}`;
        case "MM dd, YYYY":
            return `${months[month]} ${date}, ${year}`;
        case "MM dd":
            return `${months[month]} ${date}`;
        case "dd MM, T":
            return `${date} ${months[month]} - ${returnTime} `;
        case "dd MM YYYY":
            return `${date} ${months[month]} ${year} `;
        case "dd MM, YYYY | T":
            return `${date} ${months[month]}, ${year} | ${returnTime} `;
        case "dd MM, YYYY - T":
            return `${date} ${months[month]}, ${year} - ${returnTime} `;
        case "dd MM | T":
            return `${date} ${months[month]} | ${returnTime} `;
        case "day, dd":
            return `${day[fullDateTime.getDay()]}, ${date}`;
        case "day, dd MM YYYY":
            return `${day[fullDateTime.getDay()]}, ${date} ${
                months[month]
            } ${year}`;
        case "MM 'YY":
            return `${months[month]} '${year % 100}`;
        case "dd MM 'YY":
            return `${date} ${months[month]} '${year % 100}`;
        case "T":
            return returnTime;
        case "dd MM":
            return `${date} ${months[month]}`;
        case "dd MM, 'YY - T":
            return `${date} ${months[month]}, '${year % 100} - ${returnTime}`;
        case "MM dd, T":
            return `${months[month]} ${date}  , ${returnTime} `;
        default:
    }

    if (date === "01" || date === "31" || date === "21") {
        date += "st";
    } else if (date === "02" || date === "22") {
        date += "nd";
    } else if (date === "03" || date === "23") {
        date += "rd";
    } else {
        date += "th";
    }

    returnDate = `${date}${separator}${months[month]}, ${separator}${year}`;

    switch (type) {
        case "date":
            return returnDate;
        case "time":
            return returnTime;
        case "timeDate":
            return `${returnTime},  ${returnDate}`;
        case "dateTime":
            return `   ${returnTime},${separator}${returnDate}`;
        case "dayDate":
        default:
            return `${dayName}, ${returnDate}`;
    }
};

export const convertDateToEpoch = (
    date: Date | undefined | string | null | number
) => {
    if (!isDefined(date)) return null;
    return new Date(date).getTime() / 1000;
};

export const convertDaysToEpoch = (fromDays: number, toDays: number) => {
    const currentDate = new Date(); // Get the current date

    // Calculate the epoch time for "From" and "To" in seconds
    const fromEpoch = Math.floor(
        new Date(
            currentDate.setDate(currentDate.getDate() - fromDays)
        ).getTime() / 1000
    );
    const toEpoch = Math.floor(
        new Date(
            currentDate.setDate(currentDate.getDate() - toDays)
        ).getTime() / 1000
    );

    return { fromEpoch, toEpoch };
};

export const dateDifference = (date1: number, date2: number) =>
    Math.abs((date2 - date1) / (1000 * 60 * 60 * 24));

export function formatDateString(isoDate: string): string {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
    });

    return `${formattedDate} (${date.toString().match(/\((.*)\)/)?.[1]})`;
}

export function reverseFormatDateString(
    formattedDate: string | null
): string | null {
    if (!formattedDate) return null;
    const datePattern =
        /^([A-Za-z]{3}) ([A-Za-z]{3}) (\d{1,2}) (\d{4}) (\d{1,2}):(\d{1,2}):(\d{1,2}) (.*)$/;
    const regex = new RegExp(datePattern);
    const match = regex.exec(formattedDate);

    if (match) {
        const [, , month, day, year] = match;
        const monthIndex = new Date(`${month} 1, ${year}`).getMonth() + 1;
        const isoDate = `${year}-${monthIndex
            .toString()
            .padStart(2, "0")}-${day.padStart(2, "0")}`;
        return isoDate;
    }

    return null;
}

export const formatDate = (date: Date, is_start?: boolean): string => {
    const date_in_string = date.toString().split(" ");
    date_in_string[4] = is_start ? "00:00:00" : "23:59:59";
    return date_in_string.join(" ");
};

export const getCurrentQuater = (): Array<string> => {
    const now = new Date();
    const quarter = Math.floor(now.getMonth() / 3);
    const firstDate = new Date(now.getFullYear(), quarter * 3, 1);
    const endDate = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth() + 3,
        0
    );

    return [formatDate(firstDate, true), formatDate(endDate)];
};

export const getPreviousQuater = (): Array<string> => {
    const now = new Date();
    const quarter = Math.floor(now.getMonth() / 3);
    const firstDate = new Date(now.getFullYear(), quarter * 3 - 3, 1);

    const endDate = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth() + 3,
        0
    );

    return [formatDate(firstDate, true), formatDate(endDate)];
};

export function formatDateToYYYYMMDD(
    inputDateString: string | number | null
): string | null {
    if (!isDefined(inputDateString)) {
        return null;
    }
    const inputDate: Date = new Date(inputDateString);

    // Check if the date is valid
    if (isNaN(inputDate.getTime())) {
        return null;
    }

    const year: number = inputDate.getFullYear();
    const month: string = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day: string = String(inputDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

export function formatTimeHHMMSS(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const paddedMinutes =
        remainingMinutes < 10 && hours >= 1
            ? `0${remainingMinutes}`
            : `${remainingMinutes}`;

    const paddedSeconds =
        remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    return hours
        ? `${hours}:${paddedMinutes}:${paddedSeconds}`
        : `${paddedMinutes}:${paddedSeconds}`;
}

export const formatHHMMSSToSeconds = (duration: string): number | null => {
    const validRegex = new RegExp(/^(?:(?:\d*:)?([0-5]?\d):)?([0-5]?\d)$/, "g");
    if (!validRegex.exec(duration)?.length) return null;
    const [ss, mm, hh] = duration.split(":").reverse();
    return (
        Number(ss) +
        (isDefined(mm) ? Number(mm) * 60 : 0) +
        (isDefined(hh) ? Number(hh) * 3600 : 0)
    );
};

export const isDateRangeEqual = (
    range1: [string | number | Date | null, string | number | Date | null],
    range2: [string | number | Date | null, string | number | Date | null]
) => {
    const formattedRange1 = [
        range1[0] ? new Date(range1[0]).getTime() : null,
        range1[1] ? new Date(range1[1]).getTime() : null,
    ];
    const formattedRange2 = [
        range2[0] ? new Date(range2[0]).getTime() : null,
        range2[1] ? new Date(range2[1]).getTime() : null,
    ];
    if (isEqual(formattedRange1, formattedRange2)) {
        return true;
    }
};

export const getDateKey = (
    dateRange: [string | null, string | null],
    dateOptions: typeof defaultConfig.dateConfig = defaultConfig.dateConfig
): string | ((typeof dateOptions)[string] & { key: string }) => {
    for (const [key, value] of Object.entries(dateOptions)) {
        if (
            isDateRangeEqual(
                dateRange,
                value.dateRange as [
                    string | number | null,
                    string | number | null
                ]
            )
        ) {
            return key;
        }
    }
    const prefix = !isDefined(dateRange[0])
        ? "Before"
        : !isDefined(dateRange[1])
        ? "After"
        : "";
    const formattedDate0 = dateRange[0]
        ? format(new Date(dateRange[0]), "MMM dd, yyyy")
        : "";
    const formattedDate1 = dateRange[1]
        ? format(new Date(dateRange[1]), "MMM dd, yyyy")
        : "";
    const name = `${prefix} ${formattedDate0} ${
        prefix.length ? "" : "-"
    } ${formattedDate1}`;
    return {
        key: nanoid(5),
        name,
        dateRange,
        is_roling_date: false,
        label: "",
    };
};

export const getDurationKey = (
    durationRange: [number | null, number | null],
    durationOptions: typeof defaultConfig.durationConfig = defaultConfig.durationConfig
): string | ((typeof durationOptions)[string] & { key: string }) => {
    for (const [key, value] of Object.entries(durationOptions)) {
        if (isEqual(durationRange, value.value)) {
            return key;
        }
    }
    const prefix = !isDefined(durationRange[0])
        ? "Below"
        : !isDefined(durationRange[1])
        ? "Above"
        : "Between";

    const formattedDuration0 = durationRange[0]
        ? ` ${durationRange[0]} min`
        : "";
    const formattedDuration1 = durationRange[1]
        ? ` ${durationRange[1]} min`
        : "";

    const name = `${prefix} ${formattedDuration0} ${
        prefix === "Between" ? "-" : ""
    } ${formattedDuration1}`;

    return {
        key: nanoid(),
        name,
        value: durationRange,
    };
};

export const humanizedDateDifference = (
    baseDate: number,
    currDate: number
): string => {
    const dateDiff = dateDifference(baseDate, currDate);
    return Math.floor(dateDiff) === 0
        ? "Today"
        : Math.floor(dateDiff) === 1
        ? "Yesterday"
        : dateDiff < 10
        ? `${Math.floor(dateDiff)} days ago`
        : getDateTime({ isoDate: currDate, format: "dd MM 'YY" });
};

export const dateWithTimeZoneOffset = (d: string): string => {
    const date = new Date(d);

    // Get the timezone offset in minutes
    const offsetMinutes = date.getTimezoneOffset();

    // Convert the offset to milliseconds and adjust the date
    const adjustedDate = new Date(date.getTime() + offsetMinutes * 60 * 1000);

    // Convert the adjusted date to a string
    const adjustedDateString = adjustedDate.toISOString();

    return adjustedDateString;
};

export const absoluteDateField = (
    d: string,
    is_start: boolean = true
): string => {
    const date = new Date(d);
    let dd: string | number = date.getDate();
    dd = dd < 10 ? `0${dd}` : dd;
    let mm: string | number = date.getMonth() + 1;
    mm = mm < 10 ? `0${mm}` : mm;
    const yy = date.getFullYear();
    return is_start
        ? `${yy}-${mm}-${dd}T00:00:00.000Z`
        : `${yy}-${mm}-${dd}T23:59:59.999Z`;
};

export function formatMilliSecToTime(epoch: number): string {
    const date = new Date(epoch);

    let hours = date.getHours();
    const minutes = date.getMinutes();

    const amPm = hours >= 12 ? "pm" : "am";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Format minutes to always be 2 digits
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${hours}:${formattedMinutes} ${amPm}`;
}

export function getTimeInMilliseconds(datestring?: string): number {
    const date = datestring ? new Date(datestring) : new Date();
    if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
    }
    return date.getTime();
}
export const isValidDateKeyExpr = (dateKey: string | undefined | null) => {
    const isDateKeyValid =
        Boolean(dateKey) &&
        dateKey !== datekeys.custom &&
        dateKey !== datekeys.after &&
        dateKey !== datekeys.before &&
        Object.values(datekeys).find((e) => e === dateKey)
            ? true
            : false;

    return isDateKeyValid;
};
