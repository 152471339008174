import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function GptConditionSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
        >
            <rect
                x="1"
                y="1"
                width="30"
                height="30"
                rx="6"
                fill="#8741F9"
                stroke="#8741F9"
            />
            <mask
                id="mask0_2680_7555"
                maskUnits="userSpaceOnUse"
                x="6"
                y="6"
                width="20"
                height="20"
            >
                <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2680_7555)">
                <path
                    d="M21.8612 13.4948L20.8178 11.2031L18.5223 10.1615L20.8178 9.11979L21.8612 6.82812L22.9047 9.11979L25.2002 10.1615L22.9047 11.2031L21.8612 13.4948ZM21.8612 25.1615L20.8178 22.8698L18.5223 21.8281L20.8178 20.7865L21.8612 18.4948L22.9047 20.7865L25.2002 21.8281L22.9047 22.8698L21.8612 25.1615ZM13.5138 22.6615L11.427 18.0781L6.83594 15.9948L11.427 13.9115L13.5138 9.32812L15.6007 13.9115L20.1918 15.9948L15.6007 18.0781L13.5138 22.6615ZM13.5138 18.6198L14.3486 16.8281L16.1433 15.9948L14.3486 15.1615L13.5138 13.3698L12.6791 15.1615L10.8844 15.9948L12.6791 16.8281L13.5138 18.6198Z"
                    fill="white"
                />
            </g>
        </SvgIcon>
    );
}
