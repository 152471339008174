import { Box, Typography } from "@mui/material";
import { MultiResponseNodeType } from "../../context/NodeStateContext";
import useNodeContext from "../../hooks/useNodeContext";
import BlockCard from "../BlockCard";
import { BlockType, BlockTypes } from "../../constants";
import ConditionSvg from "../../svg/ConditionSvg";
import ResponseSvg from "../../svg/ResponseSvg";
import GptBlockSvg from "../../svg/GptBlockSvg";

type BlockTypes<T extends MultiResponseNodeType> = Array<{
    name: T;
    description: string;
    displayName: string;
    svgIcon: JSX.Element;
}>;

const buildingBlock: BlockTypes<MultiResponseNodeType> = [
    {
        name: "condition",
        displayName: "Conditional Block",
        description: "Add conditions to flow",
        svgIcon: <ConditionSvg />,
    },
    {
        name: "response",
        displayName: "Response Block",
        description: "Configure your responses",
        svgIcon: <ResponseSvg />,
    },
    {
        name: "condition_gpt",
        displayName: "GPT Conditional Block",
        description: "Conditions powered by LLM",
        svgIcon: <GptBlockSvg />,
    },
];

const ChooseMultiResponseNode = () => {
    const { state, dispatch, hideResponseBlock } = useNodeContext();
    const setActiveBlockType = (
        name: BlockType<MultiResponseNodeType>["name"]
    ) => {
        dispatch({ payload: { selectedBlockType: name }, type: "UPDATE" });
    };

    return (
        <>
            <Box
                className="flex items-center justify-between"
                sx={{
                    mb: 2.5,
                }}
            >
                <Typography className="font-semibold" variant="extraLarge">
                    Choose an Action
                </Typography>
            </Box>
            <Typography
                className="font-semibold uppercase"
                variant="medium"
                color="textColors.666"
                component="p"
                sx={{
                    mb: 2.5,
                }}
            >
                Building Blocks
            </Typography>
            <Box className={"grid grid-cols-2"} gap={1.5}>
                {buildingBlock
                    .filter((e) =>
                        hideResponseBlock ? e.name !== "response" : true
                    )
                    .map((block, idx) => (
                        <BlockCard<MultiResponseNodeType>
                            {...block}
                            key={idx}
                            onClick={setActiveBlockType}
                            isActive={block.name === state.selectedBlockType}
                        />
                    ))}
            </Box>
        </>
    );
};

export default ChooseMultiResponseNode;
