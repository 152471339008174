import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { AuthUserType, UserType, VoicePrint } from "@convin/type/User";
import { getUserName } from "@convin/utils/helper/common.helper";

export const usersApiSlice = createApi({
    reducerPath: "usersApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["Voice Print"],
    endpoints: (builder) => ({
        getUserInfo: builder.query<AuthUserType, void>({
            query: () => "/person/person/me/",
        }),
        getUsers: builder.query<UserType[], void>({
            query: () => ({
                url: "person/team/list_reps/",
                method: "POST",
                body: {
                    is_restricted: false,
                },
            }),
            transformResponse: (response: UserType[]) => {
                return Array.isArray(response)
                    ? response?.map((e) => ({
                          ...e,
                          label: `${getUserName(e)} (${e.email})`,
                      }))
                    : response;
            },
        }),
        getRestrictedUsers: builder.query<
            Array<UserType & { team: number; role: number }>,
            Partial<{
                teams: number[];
                is_restricted: boolean;
                tenure_range?: { gte: number; lte: number };
            }> | void
        >({
            query: (payload = {}) => ({
                url: "person/team/list_reps/",
                method: "POST",
                body: { teams: [], is_restricted: true, ...payload },
            }),
            transformResponse: (
                response: Array<UserType & { team: number; role: number }>
            ) => {
                return Array.isArray(response)
                    ? response?.map((e) => ({
                          ...e,
                          label: `${getUserName(e)} (${e.email})`,
                      }))
                    : response;
            },
        }),

        getAllAuditors: builder.query<UserType[], void>({
            query: () => "/person/list_auditor/",
            transformResponse: (response: UserType[]) => {
                return Array.isArray(response)
                    ? response?.map((e) => ({ ...e, label: getUserName(e) }))
                    : response;
            },
        }),
        getVoicePrint: builder.query<VoicePrint, void>({
            query: () => "/person/persons/voice_print/",
            providesTags: ["Voice Print"],
        }),
        uploadVoicePrint: builder.mutation<void, FormData>({
            query: (payload) => ({
                url: "/person/persons/voice_print/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Voice Print"],
        }),
        createQmsUser: builder.mutation<
            UserType,
            {
                owner_email: string;
                owner_first_name: string;
                owner_last_name: string;
                owner_primary_phone: string;
                team_id: number;
            }
        >({
            query: (payload) => ({
                url: "/person/qms/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        usersApiSlice.util.updateQueryData(
                            "getUsers",
                            undefined,
                            (draft) => {
                                Object.assign(draft, [data, ...draft]);
                            }
                        )
                    );
                } catch {}
            },
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetRestrictedUsersQuery,
    useLazyGetRestrictedUsersQuery,
    useGetAllAuditorsQuery,
    useGetUserInfoQuery,
    useGetVoicePrintQuery,
    useUploadVoicePrintMutation,
    useCreateQmsUserMutation,
} = usersApiSlice;
