import { Question } from "@convin/type/Audit";
import { getParameterResponseOptions } from "@convin/utils/helper/common.helper";
import { MutableRefObject, createContext } from "react";
import { RuleEngineConfig } from "../../types/bert";
import { useUpdateAIAuditConfigMutation } from "@convin/redux/services/settings/auditAiConfig.service";

export interface AiConfigState {
    responseType: Question["configuration_type"];
    rule_id: string | null;
    selectedResponses: RuleEngineConfig["draft_config"];
    activeResponse: RuleEngineConfig["draft_config"][0]["id"] | null;
    drawersState: Record<
        "isGroupDrawerOpen" | "isDefaultResponseDrawerOpen",
        boolean
    >;
    activeGroupBlock: {
        blockId: string;
        blockParentId?: string;
    } | null;
}
export type AiConfigStateDispatchAction =
    | {
          payload: AiConfigState["responseType"];
          type: "UPDATE_RESPONSE_TYPE" | "SET_RESPONSE_TYPE";
      }
    | {
          payload: string | number | null;
          type: "CHANGE_RESPONSE_TO_EDIT" | "REMOVE_PARAMETER_RESPONSE";
      }
    | {
          payload: AiConfigState["selectedResponses"][0];
          type: "ADD_PARAMETER_RESPONSE";
      }
    | {
          payload: AiConfigState["selectedResponses"][0];
          type: "UPDATE_RESPONSE_TREE";
      }
    | {
          payload: keyof IParameterAiConfigContext["aiConfigState"]["drawersState"];
          type: "TOGGLE_DRAWER_STATE";
      }
    | {
          payload: AiConfigState["activeGroupBlock"];
          type: "SET_ACTIVE_GROUP_BLOCK_ID";
      }
    | {
          payload: AiConfigState["selectedResponses"];
          type: "SET_SELECTED_RESPONSE";
      }
    | {
          payload: string | null;
          type: "SET_RULE_ID";
      };

export interface IParameterAiConfigContext {
    question: Question | undefined;
    isQuestionLoading: boolean;
    aiConfigState: AiConfigState;
    dispatch: React.Dispatch<AiConfigStateDispatchAction>;
    toggleDrawerState: (
        e: keyof IParameterAiConfigContext["aiConfigState"]["drawersState"]
    ) => void;
    isMultiResponseTree: boolean;
    responses: ReturnType<typeof getParameterResponseOptions>;
    canUpdate: MutableRefObject<boolean>;
    isLoadingUpdateAuditConfig: boolean;
    updateAuditConfig: ReturnType<typeof useUpdateAIAuditConfigMutation>[0];
}

export const ParameterAiConfigContext = createContext<
    IParameterAiConfigContext | undefined
>(undefined);
