import BorderedBox from "@convin/components/custom_components/BorderedBox";
import SnippetList from "@convin/components/custom_components/SnippetList";
import { ShareSvg } from "@convin/components/svg";
import { ConversationsWithSnippets } from "@convin/type/customerIntelligence";
import { getDuration } from "@convin/utils/helper";
import { getDateTime } from "@convin/utils/helper/date.helper";
import { CalendarToday, WatchLater } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export default function MonologueCard({
    data,
    toggleShareModal,
    hasShare = true,
}: {
    data: ConversationsWithSnippets;
    toggleShareModal?: (data: ConversationsWithSnippets) => void;
    hasShare?: boolean;
}) {
    const {
        id,
        title,
        search_context = [],
        start_time,
        end_time,
        meeting_type,
    } = data ?? {};

    return (
        <BorderedBox
            sx={{ mb: 2, boxShadow: "unset", borderColor: "divider" }}
            className="flex items-center"
            key={id}
        >
            <Box
                sx={{
                    borderRight: "1px solid",
                    borderColor: "divider",
                }}
                className="flex-1"
            >
                <Box
                    sx={{
                        py: 1.5,
                        px: 2,
                    }}
                >
                    <Typography
                        className="font-semibold cursor-pointer"
                        onClick={() =>
                            window.open(`/call/${id}?tab=transcript`)
                        }
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="textXs"
                        sx={(theme) => ({
                            color: theme.palette.textColors["666"],
                        })}
                        className="flex gap-2 items-center leading-5"
                    >
                        <CalendarToday className="w-4" />
                        {getDateTime({ isoDate: start_time })}
                        <WatchLater className="w-4 h-4 ml-2" />
                        {getDuration({ start_time, end_time })}
                    </Typography>
                </Box>
                {search_context.length > 0 && (
                    <Box
                        sx={{
                            py: 1.5,
                            px: 2,
                            borderTop: "1px solid",
                            borderColor: "divider",
                        }}
                    >
                        <SnippetList
                            id={id}
                            monologues={search_context || []}
                            meeting_type={meeting_type}
                        />
                    </Box>
                )}
            </Box>
            {hasShare ? (
                <Box
                    sx={{
                        p: 1.5,
                    }}
                    className="h-full cursor-pointer"
                    onClick={() =>
                        toggleShareModal ? toggleShareModal(data) : null
                    }
                >
                    <ShareSvg />
                </Box>
            ) : null}
        </BorderedBox>
    );
}
