import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { Client, ConferenceTool, tenureBucketsType } from "@convin/type/Filter";
import { PaginationType } from "@convin/type/Common";

export const filtersApiSlice = createApi({
    reducerPath: "filtersApiSlice",
    baseQuery: axiosBaseQuery({ transformResponse: (response) => response }),
    tagTypes: ["TenureBuckets"],
    endpoints: (builder) => ({
        getClients: builder.query<
            PaginationType<Client>,
            { next?: string; query?: string }
        >({
            query: ({ next, query }) => ({
                url: next || "/meeting/sales_task/list_all/",
                method: "GET",
                ...(!next && query ? { params: { query } } : {}),
            }),
        }),
        createClient: builder.mutation<
            { id: number; first_name: string; email: string | null },
            { first_name: string; email?: string }
        >({
            query: (payload) => ({
                url: "/calendar/client/create/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        filtersApiSlice.util.updateQueryData(
                            "getClients",
                            {},
                            (draft) =>
                                Object.assign(draft, {
                                    count: draft.count + 1,
                                    results: [
                                        {
                                            id: data.id,
                                            name: `${data.first_name} ${
                                                data.email ?? ""
                                            }`,
                                        },
                                        ...draft.results,
                                    ],
                                })
                        )
                    );
                } catch {}
            },
        }),
        getTenureBuckets: builder.query<tenureBucketsType, void>({
            query: () => ({
                url: "/analytics/subdomain/manage_tenure_buckets/",
                method: "GET",
            }),
            providesTags: ["TenureBuckets"],
        }),
        createTenureBucket: builder.mutation<
            { start: number; end: number }[],
            { start: number; end: number }
        >({
            query: (payload) => ({
                url: "/analytics/subdomain/manage_tenure_buckets/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["TenureBuckets"],
        }),
        deleteTenureBucket: builder.mutation<
            { start: number; end: number }[],
            { start: number; end: number }
        >({
            query: (payload) => ({
                url: "/analytics/subdomain/manage_tenure_buckets/",
                method: "DELETE",
                body: payload,
            }),
            invalidatesTags: ["TenureBuckets"],
        }),
        getConferenceTools: builder.query<ConferenceTool[], void>({
            query: () => "/meeting/conference_tools/",
        }),
        getTopics: builder.query<{ id: number; name: string }[], void>({
            query: () => "/topic/topic/list_all/",
        }), //TODO: Remove endpoint after topic manager merge
    }),
});

export const {
    useGetClientsQuery,
    useLazyGetClientsQuery,
    useCreateClientMutation,
    useGetConferenceToolsQuery,
    useGetTopicsQuery,
    useGetTenureBucketsQuery,
    useCreateTenureBucketMutation,
    useDeleteTenureBucketMutation,
} = filtersApiSlice;
