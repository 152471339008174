import { Box, Button, Divider, Typography } from "@mui/material";
import type {
    BuildingBlockType,
    MultiResponseNodeType,
    SingleResponseBlockType,
} from "../../context/NodeStateContext";
import useNodeContext from "../../hooks/useNodeContext";
import SingleBlockSvg from "../../svg/SingleBlockSvg";
import GroupBlockSvg from "../../svg/GroupBlockSvg";
import BlockCard from "../BlockCard";
import { BlockType, BlockTypes } from "../../constants";
import useParameterAiConfigContext from "../../hooks/useParameterAiConfigContext";
import { useReactFlowStateContextProvider } from "../../hooks/useReactFlowStateContextProvider";
import { useEffect } from "react";
import {
    MultiResponseBlockData,
    GroupNodeType,
    MultipleResponseNode,
    DefaultNodeType,
} from "../../../types/bert";
import { isDefined } from "@convin/utils/helper/common.helper";
import type { Node } from "reactflow";
import { useNodeInfoContext } from "../../context/NodeInfoContext";

type BlockTypes<T extends SingleResponseBlockType | BuildingBlockType> = Array<{
    name: T;
    description: string;
    displayName: string;
    svgIcon: JSX.Element;
}>;

const buildingBlock: BlockTypes<BuildingBlockType> = [
    {
        name: "single",
        displayName: "Single Block",
        description: "Build an elemental rule",
        svgIcon: <SingleBlockSvg />,
    },
    {
        name: "group",
        displayName: "Group Block",
        description: "Create group of blocks",
        svgIcon: <GroupBlockSvg />,
    },
];

const ChooseSingleResponseBlock = () => {
    const { nodeType } = useNodeInfoContext();
    const { dispatch: updateAiConfigState, isMultiResponseTree } =
        useParameterAiConfigContext();
    const {
        isGroupBlockTree,
        createSingleResponseNode,
        setNodes,
        isDefaultRuleConfig,
    } = useReactFlowStateContextProvider();
    const {
        state,
        dispatch,
        sourceId,
        direction,
        onNodeSaveCallBack,
        showDefaultRuleButton,
        blockParentId,
    } = useNodeContext();
    const { toggleDrawerState } = useParameterAiConfigContext();
    const setActiveBlockType = (
        name: BlockType<SingleResponseBlockType>["name"]
    ) => {
        dispatch({ payload: { selectedBlockType: name }, type: "UPDATE" });
    };

    const setBuildingBlockType = (
        name: BlockType<BuildingBlockType>["name"]
    ) => {
        dispatch({ payload: { buildingBlockType: name }, type: "UPDATE" });
        if (name === "group" && !isGroupBlockTree) {
            if (
                isDefined(sourceId) &&
                isMultiResponseTree &&
                isDefined(blockParentId)
            ) {
                setNodes((nodes) =>
                    nodes.map((n: Node<MultipleResponseNode<"condition">>) =>
                        n.id === blockParentId
                            ? {
                                  ...n,
                                  data: {
                                      ...n.data,
                                      metadata: {
                                          ...n.data.metadata,
                                          blocks: n.data.metadata.blocks.map(
                                              (
                                                  e: MultiResponseBlockData<MultiResponseNodeType>["blocks"][0]
                                              ) =>
                                                  e.id === sourceId
                                                      ? {
                                                            ...e,

                                                            data: {
                                                                ...e.data,
                                                                type: "group_block",
                                                                metadata: {
                                                                    ...e.data
                                                                        .metadata,
                                                                    nodes: [],
                                                                    edges: [],
                                                                },
                                                            },
                                                        }
                                                      : e
                                          ),
                                      },
                                  },
                              }
                            : n
                    )
                );
                updateAiConfigState({
                    type: "SET_ACTIVE_GROUP_BLOCK_ID",
                    payload: {
                        blockId: sourceId,
                        blockParentId,
                    },
                });
                onNodeSaveCallBack();
            } else {
                const id = createSingleResponseNode<GroupNodeType>({
                    direction,
                    sourceId,
                    data: {
                        type: "group_block",
                        is_not: false,
                        metadata: {
                            name: "Untitled",
                            nodes: [],
                            edges: [],
                        },
                    },
                });
                updateAiConfigState({
                    type: "SET_ACTIVE_GROUP_BLOCK_ID",
                    payload: {
                        blockId: id,
                    },
                });
            }

            onNodeSaveCallBack();
        }
    };

    useEffect(() => {
        if (
            (isGroupBlockTree || isDefaultRuleConfig) &&
            state.buildingBlockType !== "single"
        ) {
            setBuildingBlockType("single");
        }
    }, [isGroupBlockTree, isDefaultRuleConfig]);

    const flag = !isGroupBlockTree && !isDefaultRuleConfig;
    return (
        <>
            {flag && (
                <>
                    <Box
                        className="flex items-center justify-between"
                        sx={{
                            mb: 2.5,
                        }}
                    >
                        <Typography
                            className="font-semibold"
                            variant="extraLarge"
                        >
                            Choose an Action
                        </Typography>
                        {showDefaultRuleButton && (
                            <Typography
                                className="font-semibold cursor-pointer"
                                variant="small"
                                sx={(theme) => ({
                                    color: theme.palette.primary.main,
                                })}
                                onClick={() => {
                                    createSingleResponseNode<DefaultNodeType>({
                                        data: {
                                            type: "default",
                                            metadata: {
                                                blocks: [],
                                                selected: null,
                                            },
                                        },
                                    });
                                    toggleDrawerState(
                                        "isDefaultResponseDrawerOpen"
                                    );
                                }}
                            >
                                Default Rule
                            </Typography>
                        )}
                    </Box>
                    <Typography
                        className="font-semibold uppercase"
                        variant="medium"
                        color="textColors.666"
                        component="p"
                        sx={{
                            mb: 2.5,
                        }}
                    >
                        Building Blocks
                    </Typography>
                    <Box className="grid grid-cols-2" gap={1.5}>
                        {buildingBlock.map((block, idx) => (
                            <BlockCard<BuildingBlockType>
                                {...block}
                                key={idx}
                                onClick={setBuildingBlockType}
                                isActive={
                                    block.name === state.buildingBlockType
                                }
                            />
                        ))}
                    </Box>
                </>
            )}

            {state.buildingBlockType === "single" || !flag ? (
                <>
                    {!flag ? (
                        <></>
                    ) : (
                        <Divider
                            sx={{
                                my: 1.5,
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            my: 1.5,
                        }}
                    >
                        <Typography
                            className="font-semibold uppercase"
                            variant="medium"
                            color="textColors.666"
                            sx={{
                                mb: 1.5,
                            }}
                            component="p"
                        >
                            Block Type
                        </Typography>
                        <Box className="grid grid-cols-2" gap={1.5}>
                            {BlockTypes.filter((e) =>
                                nodeType === "condition_gpt"
                                    ? e.name === "moment"
                                    : true
                            ).map((block, idx) => (
                                <BlockCard<SingleResponseBlockType>
                                    {...block}
                                    key={idx}
                                    onClick={setActiveBlockType}
                                    isActive={
                                        block.name === state.selectedBlockType
                                    }
                                />
                            ))}
                        </Box>
                    </Box>
                </>
            ) : (
                <></>
            )}

            {isDefaultRuleConfig && (
                <Button
                    variant="outlined"
                    className="absolute right-0 bottom-0"
                    onClick={onNodeSaveCallBack}
                >
                    Back
                </Button>
            )}
        </>
    );
};

export default ChooseSingleResponseBlock;
