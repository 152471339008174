import { createApi } from "@reduxjs/toolkit/query/react";

import {
    Category,
    CategoryPayload,
    CategorySequencePayload,
    Question,
    QuestionPayload,
    QuestionSequencePayload,
    Template,
    TemplatePayload,
    TemplateRetrieveInfoResponse,
    TemplateWithCategories,
} from "@convin/type/Audit";
import axiosBaseQuery from "../axiosBaseQuery";
import { Violation } from "@convin/type/Violation";
import {
    isDefined,
    updateDownloadQue,
} from "@convin/utils/helper/common.helper";
import {
    Calibration,
    CalibrationByQuestion,
    CalibrationCreateApiPayload,
    CalibrationResponse,
    CalibrationUpdatePayload,
    MoveMeetingsToAccountsPayload,
    ReCalibratePayload,
    ReCalibrateResponse,
} from "@convin/type/Calibration.model";
import { DownloadReport } from "@convin/type/Report";

export const auditManagerApiSlice = createApi({
    reducerPath: "auditManagerApiSlice",
    tagTypes: [
        "Templates",
        "Categories",
        "Parameters",
        "Calibrations",
        "Calibration Report",
    ],
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getTemplates: builder.query<Template[], void>({
            query: () => "/audit/template/list_all/?all=true",
            providesTags: ["Templates"],
            transformResponse: (response: Template[]) => {
                return Array.isArray(response)
                    ? response?.map((e) => ({ ...e, label: e.name }))
                    : response;
            },
        }),
        createTemplate: builder.mutation<Template, TemplatePayload>({
            query: (payload) => ({
                url: "/audit/template/create/",
                method: "POST",
                body: {
                    ...payload,
                    tags: [
                        {
                            tag_name: "Critical",
                            tag_type: "scoring tag",
                            rules: ["score_p_no_c_no"],
                        },
                        {
                            tag_name: "Fatal",
                            tag_type: "scoring tag",
                            rules: ["score_p_no_c_no"],
                        },
                    ],
                },
            }),
        }),
        updateTemplate: builder.mutation<
            Template,
            Pick<Template, "id"> & Partial<TemplatePayload>
        >({
            query: ({ id, ...payload }) => ({
                url: `/audit/template/update/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(
                { id, is_disabled },
                { queryFulfilled, dispatch }
            ) {
                const patchResult = dispatch(
                    auditManagerApiSlice.util.updateQueryData(
                        "getTemplates",
                        undefined,
                        (draft) => {
                            Object.assign(
                                draft,
                                draft.map((e) =>
                                    e.id === id ? { ...e, is_disabled } : e
                                )
                            );
                        }
                    )
                );
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditManagerApiSlice.util.updateQueryData(
                            "getTemplateById",
                            id.toString(),
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getTemplateById: builder.query<Template, string | number>({
            query: (id) => `/audit/template/retrieve/${id}/`,
            keepUnusedDataFor: 0,
        }),
        getTemplateInfoById: builder.query<
            TemplateWithCategories,
            { template_id: number; questions?: string }
        >({
            query: (params) => ({
                url: "audit/template/retrieve-info/",
                params,
            }),
        }),
        getCategoriesByTemplateId: builder.query<Category[], number>({
            query: (id) => `/audit/category/list_all/${id}/`,
            keepUnusedDataFor: 0,
            providesTags: ["Categories"],
        }),
        getCategory: builder.query<Category, number>({
            query: (id) => `/audit/category/retrieve/${id}/`,
            keepUnusedDataFor: 0,
        }),
        createCategory: builder.mutation<Category, Partial<CategoryPayload>>({
            query: (payload) => ({
                url: "/audit/category/create/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["Categories"] : [],
        }),
        updateCategory: builder.mutation<
            Category,
            Pick<Category, "id"> & Partial<CategoryPayload>
        >({
            query: ({ id, ...payload }) => ({
                url: `/audit/category/update/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(
                { template, id, ...rest },
                { queryFulfilled, dispatch }
            ) {
                const patchResult = dispatch(
                    auditManagerApiSlice.util.updateQueryData(
                        "getCategoriesByTemplateId",
                        template as number,
                        (draft) => {
                            Object.assign(
                                draft,
                                draft.map((e) =>
                                    e.id === id ? { ...e, ...rest } : e
                                )
                            );
                        }
                    )
                );
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditManagerApiSlice.util.updateQueryData(
                            "getCategoriesByTemplateId",
                            template as number,
                            (draft) => {
                                Object.assign(
                                    draft,
                                    draft.map((e) => (e.id === id ? data : e))
                                );
                            }
                        )
                    );
                    if (data.is_disabled)
                        dispatch(
                            auditManagerApiSlice.util.updateQueryData(
                                "getParameterByCategoryId",
                                data.id,
                                (draft) => {
                                    Object.assign(
                                        draft,
                                        draft.map((e) => ({
                                            ...e,
                                            is_disabled: true,
                                        }))
                                    );
                                }
                            )
                        );
                } catch {
                    patchResult.undo();
                }
            },
        }),
        updateCategorySequence: builder.mutation<
            { status: boolean },
            CategorySequencePayload
        >({
            query: (payload) => ({
                url: "/audit/category/seq_no/bulk_update/",
                method: "PATCH",
                body: payload,
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["Categories"] : [],
        }),
        getParameterByCategoryId: builder.query<Question[], number>({
            query: (id) => `/audit/question/list_all/${id}/`,
            providesTags: ["Parameters"],
        }),
        getParameterById: builder.query<Question, number>({
            query: (id) => `/audit/question/retrieve/${id}/`,
        }),
        createParameter: builder.mutation<Question, Partial<QuestionPayload>>({
            query: (payload) => ({
                url: "/audit/question/create/",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["Parameters"] : [],
        }),
        updateParameterById: builder.mutation<
            Question,
            Pick<QuestionPayload, "id"> & Partial<QuestionPayload>
        >({
            query: ({ id, ...payload }) => ({
                url: `/audit/question/update/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            async onQueryStarted(
                { id, category, is_disabled },
                { dispatch, queryFulfilled }
            ) {
                const patchResult = dispatch(
                    auditManagerApiSlice.util.updateQueryData(
                        "getParameterByCategoryId",
                        category as number,
                        (draft: Question[]) => {
                            Object.assign(
                                draft,
                                draft.map((e) =>
                                    e.id === id ? { ...e, is_disabled } : e
                                )
                            );
                        }
                    )
                );
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        auditManagerApiSlice.util.updateQueryData(
                            "getParameterByCategoryId",
                            data.category.id,
                            (draft: Question[]) => {
                                Object.assign(
                                    draft,
                                    draft.map((e) => (e.id === id ? data : e))
                                );
                            }
                        )
                    );
                    dispatch(
                        auditManagerApiSlice.util.updateQueryData(
                            "getParameterById",
                            data.id,
                            (draft: Question) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                } catch {
                    patchResult.undo();
                }
            },
        }),
        updateParameterSequence: builder.mutation<
            { status: boolean },
            QuestionSequencePayload
        >({
            query: (payload) => ({
                url: "/audit/question/seq_no/bulk_update/",
                method: "PATCH",
                body: payload,
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["Parameters"] : [],
        }),
        getViolations: builder.query<Violation[], void>({
            query: () => "audit/violation/list_all/",
            transformResponse: (response: Violation[]) => {
                return Array.isArray(response)
                    ? response?.map((e) => ({ ...e, label: e.name }))
                    : response;
            },
        }),
        cloneTemplate: builder.mutation<Template, number>({
            query: (id) => ({
                url: "/audit/template/clone/",
                method: "POST",
                body: {
                    template_id: id,
                },
            }),
        }),
        getAllCalibrations: builder.query<Calibration[], void>({
            query: () => "/audit/calibration/list_all/",
            providesTags: ["Calibrations"],
        }),
        createCalibration: builder.mutation<
            CalibrationResponse,
            CalibrationCreateApiPayload
        >({
            query: ({ is_manual, ...payload }) => ({
                url: "/audit/calibration/create/",
                method: "POST",
                body: payload,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: { is_manual },
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["Calibrations"] : [],
        }),
        updateCalibration: builder.mutation<
            CalibrationResponse,
            CalibrationUpdatePayload
        >({
            query: ({ id, ...payload }) => ({
                url: `/audit/calibration/update/${id}`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["Calibrations"] : [],
        }),
        getCalibrationByQuestion: builder.query<
            CalibrationByQuestion,
            { calibration_id: number; question_id: number }
        >({
            query: ({ calibration_id, question_id }) => ({
                url: `audit/calibration/${calibration_id}/`,
                method: "GET",
                params: {
                    type: "question_report",
                    qid: question_id,
                },
            }),
            providesTags: ["Calibration Report"],
            keepUnusedDataFor: 0,
        }),
        getRecalibrationStatus: builder.query<
            Record<number, ReCalibrateResponse>,
            number
        >({
            queryFn: () => ({ data: {} }),
        }),
        reCalibrateAll: builder.mutation<
            ReCalibrateResponse,
            { id: number; question_id: number[] }
        >({
            query: ({ id, question_id }) => ({
                url: `/audit/calibration/${id}/?type=calibrate`,
                method: "POST",
                body: {
                    question_id,
                },
            }),
        }),
        moveMeetingsToAccounts: builder.mutation<
            void,
            MoveMeetingsToAccountsPayload
        >({
            query: ({ template_id, calibration_id }) => ({
                url: "/audit/account/migrate/",
                method: "POST",
                body: {
                    template_id: Number(template_id),
                    calibration_id: Number(calibration_id),
                },
            }),
        }),
        reCalibrateByCallId: builder.mutation<
            ReCalibrateResponse,
            ReCalibratePayload
        >({
            query: ({ call_id, level }) => ({
                url: `/audit/template/run?type=audit&id=${call_id}&level=${level}`,
                method: "GET",
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["Calibration Report"] : [],
        }),
        downloadAccuracyReport: builder.query<
            DownloadReport,
            {
                start_time?: string | null | number;
                end_time?: string | null | number;
                template_id: number;
                auditors?: string;
            }
        >({
            query: (params) => ({
                url: "/audit/template/run",
                params: {
                    group_by: "template",
                    type: "report",
                    level: "meeting",
                    ...params,
                },
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        getTemplateRetrieveInfo: builder.query<
            TemplateRetrieveInfoResponse,
            { template_id: number; quetions?: "all" }
        >({
            query: (params) => ({
                url: "audit/template/retrieve-info/",
                method: "GET",
                params,
            }),
        }),
        getInternalAuditReport: builder.mutation<
            DownloadReport,
            {
                calibration_id: number;
                type:
                    | "calibration_report"
                    | "comparison_report"
                    | "recall_report";
            }
        >({
            query: ({ calibration_id, ...params }) => ({
                url: `/audit/calibration/${calibration_id}/`,
                method: "GET",
                params: { ...params, group_by_template: true },
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        dispatch,
                        data,
                        type: "internal",
                    });
                } catch (err) {}
            },
        }),
    }),
});

export const {
    useGetViolationsQuery,
    useGetTemplatesQuery,
    useCreateTemplateMutation,
    useGetTemplateByIdQuery,
    useGetTemplateInfoByIdQuery,
    useUpdateTemplateMutation,
    useGetCategoriesByTemplateIdQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useGetParameterByCategoryIdQuery,
    useCreateParameterMutation,
    useUpdateParameterByIdMutation,
    useGetCategoryQuery,
    useUpdateCategorySequenceMutation,
    useUpdateParameterSequenceMutation,
    useCloneTemplateMutation,
    useGetAllCalibrationsQuery,
    useCreateCalibrationMutation,
    useUpdateCalibrationMutation,
    useGetRecalibrationStatusQuery,
    useGetCalibrationByQuestionQuery,
    useReCalibrateByCallIdMutation,
    useReCalibrateAllMutation,
    useGetParameterByIdQuery,
    useLazyDownloadAccuracyReportQuery,
    useGetTemplateRetrieveInfoQuery,
    useMoveMeetingsToAccountsMutation,
    useGetInternalAuditReportMutation,
} = auditManagerApiSlice;
