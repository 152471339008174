import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function AIFeatureStickSvg({
    sx,
    onClick,
}: {
    sx?: SxProps<Theme>;
    onClick?: () => void;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 20 20"
            sx={{ width: 20, height: 20, fill: "none", ...sx }}
            onClick={onClick}
        >
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
                <g>
                    <path
                        d="M9.64591 3.89426L9.32414 3.19401L8.6239 2.87224L9.32414 2.55963L9.64591 1.85938L9.95853 2.55963L10.6588 2.87224L9.95853 3.19401L9.64591 3.89426ZM4.2879 3.89426L3.96614 3.19401L3.26589 2.87224L3.96614 2.55963L4.2879 1.85938L4.60966 2.55963L5.30991 2.87224L4.60966 3.19401L4.2879 3.89426ZM9.64591 9.25226L9.32414 8.55201L8.6239 8.23026L9.32414 7.9085L9.64591 7.20824L9.95853 7.9085L10.6588 8.23026L9.95853 8.55201L9.64591 9.25226ZM2.65104 10.5945L1.92364 9.86712C1.85471 9.79607 1.8178 9.71427 1.81293 9.6217C1.80804 9.52913 1.84495 9.44655 1.92364 9.37396L6.75464 4.55211C6.83362 4.48135 6.9232 4.44598 7.02339 4.44598C7.12359 4.44598 7.20907 4.48135 7.27983 4.55211L7.98435 5.25663C8.05511 5.32951 8.09048 5.41605 8.09048 5.51624C8.09048 5.61644 8.05511 5.70497 7.98435 5.78182L3.1442 10.5945C3.07132 10.6653 2.9886 10.7007 2.89603 10.7007C2.80346 10.7007 2.72179 10.6653 2.65104 10.5945ZM2.87885 10.3283L6.4463 6.76998L5.74544 6.06912L2.18715 9.63655L2.87885 10.3283Z"
                        fill="url(#paint0_linear_1322_11669)"
                        stroke="url(#paint1_linear_1322_11669)"
                        strokeWidth="0.6"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_1322_11669"
                        x1="3.52183"
                        y1="2.66352"
                        x2="8.34363"
                        y2="8.9957"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF1B79" />
                        <stop offset="0.432292" stopColor="#FF4E60" />
                        <stop offset="0.994792" stopColor="#2D66E8" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_1322_11669"
                        x1="3.99689"
                        y1="2.00056"
                        x2="5.99222"
                        y2="10.5731"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF1B79" />
                        <stop offset="0.432292" stopColor="#FF4E60" />
                        <stop offset="0.994792" stopColor="#2D66E8" />
                    </linearGradient>
                    <clipPath id="clip0_1322_11669">
                        <rect
                            width="11.4187"
                            height="11.4187"
                            fill="white"
                            transform="translate(0.28125 0.851562)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
